import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {pick} from 'lodash';

import moment from 'infra/moment';
import {humanPriceFromCents} from 'infra/formatters/money';
import {shortCutoffTimeAndDay, humanDayOfWeek, humanTimeRangeChoice} from 'infra/formatters/time';
import Alert from 'web/components/alert';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import MinimalLayout from 'web/components/minimal_layout';
import {transformOfferForTracking} from 'web/helpers/serializers/transform_offer_for_tracking';

import StepsHeader from '../components/steps_header';
import {actions, reducer} from './duck';

function getCutoffText(cutoffDate, tzid) {
  return `order by ${shortCutoffTimeAndDay(cutoffDate, tzid)}`;
}

function getStatusText(fulfillmentOffer, tzid) {
  const {status, unavailableCustomerReason, cutoffDate} = fulfillmentOffer;
  const now = moment().tz(tzid);
  switch (status) {
    case 'unavailable':
      return unavailableCustomerReason;
    case 'soldout':
      return now.isAfter(moment.tz(cutoffDate, tzid))
        ? getCutoffText(cutoffDate, tzid)
        : 'sold out';
    case 'closed':
      return getCutoffText(cutoffDate, tzid);
    case 'available':
      return getCutoffText(cutoffDate, tzid);
  }
}

// TODO (@shermam) This component is duplicating the
// formatting of texts for fulfillment offer that is already
// present in the serialized version of the fulfillment offer.
// We can come back here and use the existing props instead
export class FulfillmentOfferRow extends Component {
  chooseFulfillmentOffer() {
    this.props.chooseFulfillmentOffer(this.props.index);
  }

  render() {
    const {index, tzid, fulfillmentOffer} = this.props;
    return (
      <div
        className={`fulfillment-offer ${
          fulfillmentOffer.status !== 'available' ? 'unavailable' : ''
        } delivery js-delivery-item-${index + 1}`}
        onClick={this.chooseFulfillmentOffer.bind(this)}
      >
        <div className="details">
          <div className="name">
            {humanTimeRangeChoice(fulfillmentOffer.startAt, fulfillmentOffer.endAt, tzid)}
            <span className="cutoff"> ({getStatusText(fulfillmentOffer, tzid)})</span>
          </div>
        </div>
        {fulfillmentOffer.status === 'available' ? (
          <div className="select">
            <span className="link-button">
              Select <i className="icon icon-chevron-thin right" />
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}

FulfillmentOfferRow.propTypes = {
  index: PropTypes.number.isRequired,
  tzid: PropTypes.string.isRequired,
  fulfillmentOffer: PropTypes.shape({
    status: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    cutoffDate: PropTypes.string.isRequired,
    unavailableCustomerReason: PropTypes.string,
  }).isRequired,
  chooseFulfillmentOffer: PropTypes.func.isRequired,
};

export class DeliveryOptionsPage extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  static reducer(state, action) {
    return reducer(state, action);
  }

  componentDidMount() {
    const clientSettings = this.context;

    window.metrics.track('Delivery Window Options Viewed', {
      windowsViewed: this.props.fulfillmentOffers?.map((offer) =>
        transformOfferForTracking({tzid: clientSettings.tzid, offer}),
      ),
    });
  }

  renderDeliveryChooser() {
    const clientSettings = this.context;

    return (
      <div id="delivery-tab-view">
        {this.props.amountToMinimum ? (
          <div id="deliveries-minimum" className="raised-tile">
            <div className="raised-tile__body">
              <div className="minimum-msg">
                {humanPriceFromCents(this.props.orderMinimum, {short: true})} minimum
              </div>
              <p className="min-info">
                Order {humanPriceFromCents(this.props.amountToMinimum)} more.
                <br />
              </p>
              <a className="button" href={`/`}>
                back to the market
              </a>
              <p className="min-why">
                Minimum delivery sizes help us
                <br />
                operate a sustainable business.
              </p>
            </div>
          </div>
        ) : (
          <div id="deliveries-list">
            <div className="fulfillment-offer-list delivery-times raised-tile">
              <div className="price raised-tile__header js-price-header">choose your window</div>
              {this.props.fulfillmentOffers.map((fulfillmentOffer, index) => (
                <FulfillmentOfferRow
                  key={index}
                  index={index}
                  tzid={clientSettings.tzid}
                  fulfillmentOffer={fulfillmentOffer.offer}
                  chooseFulfillmentOffer={this.props.chooseFulfillmentOffer}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const clientSettings = this.context;

    return (
      <MinimalLayout>
        <Helmet>
          <title>Checkout | Good Eggs</title>
          {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
          <html className="with-fixed-minimal-footer" />
        </Helmet>

        <StepsHeader currentStep={1} />

        <div id="fulfillment-offer-chooser-view" className="narrow-page gutter">
          {this.props.errorMessage ? (
            <div className="delivery-tab-view__warning">
              <Alert type="warning" heading={this.props.errorMessage}>
                <div className="checkout__warning-contents">Please pick a new time below.</div>
              </Alert>
            </div>
          ) : null}

          <h1 className="checkout__title">
            Choose a delivery time for{' '}
            {humanDayOfWeek(this.props.fulfillmentDay, clientSettings.tzid)}
          </h1>

          {this.renderDeliveryChooser()}

          <div className="basket-page__back-link">
            <a className="back-to-basket checkout__link" href="/basket">
              <i className="icon icon-chevron left" />
              Return to basket
            </a>
          </div>
        </div>
      </MinimalLayout>
    );
  }
}

DeliveryOptionsPage.propTypes = {
  errorMessage: PropTypes.string,
  amountToMinimum: PropTypes.number,
  orderMinimum: PropTypes.number,
  fulfillmentDay: PropTypes.string.isRequired,
  fulfillmentOffers: PropTypes.array.isRequired,
  chooseFulfillmentOffer: PropTypes.func.isRequired,
};

export function mapStateToProps(state) {
  return pick(state, [
    'errorMessage',
    'amountToMinimum',
    'orderMinimum',
    'fulfillmentDay',
    'fulfillmentOffers',
  ]);
}

const ConnectedPaymentDetailsPage = connect(mapStateToProps, actions)(DeliveryOptionsPage);

ConnectedPaymentDetailsPage.pageName = 'Delivery Options';

export default ConnectedPaymentDetailsPage;
