import update from 'immutability-helper';

import moment from 'infra/moment';
import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';
import {transformOfferForTracking} from 'web/helpers/serializers/transform_offer_for_tracking';

import {updateFulfillmentOffer} from './api_client';

export const DELIVERY_OPTIONS_PAGE_START_SAVING = 'DELIVERY_OPTIONS_PAGE_START_SAVING';
export const DELIVERY_OPTIONS_PAGE_SAVE_ERROR = 'DELIVERY_OPTIONS_PAGE_SAVE_ERROR';

export const actions = {
  chooseFulfillmentOffer: (index) => (dispatch, getState) => {
    const {fulfillmentOffers, uiProductsById, basket, deliveryRestrictedMinimumWindowStart} =
      getState();
    const serializedOffer = fulfillmentOffers[index];
    const fulfillmentOffer = serializedOffer.offer;

    dispatch({type: DELIVERY_OPTIONS_PAGE_START_SAVING});

    return updateFulfillmentOffer(fulfillmentOffer)
      .then(() => {
        const basketContainsRestrictedItem = Boolean(
          basket.items.find((item) => uiProductsById[item.product.id].isDeliveryRestricted),
        );
        const inRestrictedWindow =
          moment.tz(fulfillmentOffer.startAt, window.settings.tzid).hour() <
          deliveryRestrictedMinimumWindowStart;
        if (basketContainsRestrictedItem && inRestrictedWindow) {
          // restricted items, if we've jumped into a bad window, bump to basket page
          dispatch(
            navigationActions.navigate(
              '/basket?errorMessage=Please%20double%20check%20your%20basket.&errorType=DELIVERY_RESTRICTED',
            ),
          );
          return;
        }

        window.metrics.track('Delivery Window Selected', {
          windowChosen: transformOfferForTracking({
            tzid: window.settings.tzid,
            offer: serializedOffer,
          }),
        });

        dispatch(navigationActions.navigate('/basket/review'));
      })
      .catch((e) => {
        const error = {
          type: e.type || 'UNKNOWN',
          customerMessage:
            e.customerMessage ||
            'Oops - that’s not working quite right. Please try again in a few moments.',
        };
        dispatch({
          type: DELIVERY_OPTIONS_PAGE_SAVE_ERROR,
          error,
        });
      });
  },
};

export function reducer(state, action) {
  const updated = update(state, {
    navigation: {$set: navigationReducer(state.navigation, action)},
  });

  switch (action.type) {
    case DELIVERY_OPTIONS_PAGE_START_SAVING: {
      return update(updated, {$unset: ['errorMessage']});
    }
    case DELIVERY_OPTIONS_PAGE_SAVE_ERROR: {
      return update(updated, {
        errorMessage: {$set: action.error.customerMessage},
      });
    }
  }

  return updated;
}
